*{
  font-family:  sans-serif, Tahoma;
}

.overlay{
  visibility: hidden;
  padding: 10px;
  position:fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.255);
}
.popUp{
  visibility: hidden;
}
.hidden{
  display:none;
}

.loader{
  border:15px solid #f3f3f3;
  border-top:15px solid #2277b0 ;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin{
  0%{
    transform:rotate(0deg);
  }
  100%{
    transform: rotate(360deg)
  }
}